import * as Sentry from '@sentry/vue';
import Vue from 'vue';

const ENV = process.env.VUE_APP_SENTRY_ENV || process.env.NODE_ENV;

if (!['development', 'test'].includes(ENV) && !window.Cypress) {
    Sentry.init({
        app: Vue,
        dsn: 'https://44d76681381e4ddb9d29f85edad5b364@o527262.ingest.sentry.io/5658856',
        environment: ENV || 'production',
        ignoreErrors: ['Object Not Found Matching Id'],
        debug: ENV === 'staging',
        integrations: [
            Sentry.browserTracingIntegration()
        ],
        tracesSampleRate: 1.0,
    });
}
