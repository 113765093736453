import activity from '@imt/vue-activity/src/store';
import alerts from '@imt/vue-admin-menus/src/store/alerts';
import notifications from '@imt/vue-admin-menus/src/store/notifications';
import {configuration} from '@imt/vue-configuration/src/store';
import {asString, defaultParamMap} from "@imt/vue-list/src/utils/queryParams";
import toolbox from '@imt/vue-toolbox/src/store';
import utils, {
    axiosWithAuth,
    getCookieValue,
    logoutUser,
    setCookieValue,
} from '@imt/vue-toolbox/src/utils';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import twoFactor from '@/store/twoFactor';
import unauthenticated from '@/store/unauthenticated';

export let authedAxios = axiosWithAuth();

export const getters = {
    activityErrorMessages() {
        return {
            no_recent: 'No recent activity found.',
            no_your_recent: 'You don\'t have any recent activity.',
            unable_to_connect: 'Unable to connect to Activity Center.',
            unable_to_fetch: 'Unable to fetch activity.',
            unable_to_fetch_your_recent: 'Unable to fetch your recent activity.',
        };
    },
    legacyURL() {
        return process.env.VUE_APP_LEGACY_ENV !== 'production' ? `https://${process.env.VUE_APP_LEGACY_ENV}.legacy.imtins.com` : 'https://www.imtins.com';
    },
    availableSystems(state) {
        return state.configuration.configurations?.find(c => c.configurationType === 'available_systems')?.data || {};
    },
    booleanOptions() {
        return [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ];
    },
    defaultPhoneNumbers(state) {
        return state.configuration.configurations?.find(
            c => c.configurationType === 'default_phone_numbers'
        )?.data || {};
    },
    departmentOptions(state) {
        return state.departments.map(department => ({text: department, value: department}));
    },
    getSystemName: (state, otherGetters) => systemKey => {
        return otherGetters.availableSystems[systemKey];
    },
    managerOptions(state) {
        return state.managers.map(manager => ({text: manager, value: manager}));
    },
    getTokenFromCookie: getCookieValue,
    statusOptions() {
        return [
            {
                label: 'Active',
                value: true,
            },
            {
                label: 'Inactive',
                value: false,
            },
        ];
    },
    userTypeOptions() {
        return [
            {
                text: 'Agent',
                value: 'agent',
            },
            {
                text: 'External',
                value: 'external',
            },
            {
                text: 'Insured',
                value: 'insured',
            },
            {
                text: 'Internal',
                value: 'internal',
            },
            {
                text: 'System',
                value: 'system',
            },
            {
                text: 'Mutual',
                value: 'mutual',
            },
        ];
    },
};

export const actions = {
    async createMPPAccount(context, data) {
        const response = await axios.post(
            `${context.getters.legacyURL}/imtonline/platform_legacy_integration/mpp/create_mpp_account.pl`,
            {
                data: {
                    origin: 'PLATFORM',
                    key: data.clientKey,
                    first_name: data.firstName,
                    last_name: data.lastName,
                    email: data.email,
                }
            },
            {
                headers: {'Content-Type': 'text/plain'}
            }
        );

        return response.data;
    },
    async login(context, {
        data,
        url = '/tokens/',
    }) {
        const response = await authedAxios.post(
                url,
                utils.dataFormatter.serialize({
                    stuff: {
                        ...data,
                        type: 'Token',
                    },
                }),
            ),
            responseData = utils.dataFormatter.deserialize(response.data);

        if (!responseData.twoFactorRequired) {
            context.commit('SET_AUTH_TOKEN', responseData.auth);
        }

        return responseData;
    },
    logout(context) {
        logoutUser();
        context.commit('SET_AUTH_TOKEN', '');
    },
    async fetchClientInfo(context, data) {
        const queryString = `?origin=PLATFORM&last_name=${data.lastNameBusinessName}&zip_code=${data.zipCode}&policy_number=${data.accountPolicyNumber}`,
            response = await axios.get(`${context.getters.legacyURL}/imtonline/platform_legacy_integration/mpp/get_client_information.pl${queryString}`);

        return response.data;
    },
    async fetchDepartments(context) {
        const response = await authedAxios.get('/departments/'),
            departments = response?.data?.data || [];

        context.commit('SET_DATA', {field: 'departments', data: departments});

        return departments;
    },
    async fetchManagers(context) {
        const response = await authedAxios.get('/managers/'),
            managers = response?.data?.data || [];

        context.commit('SET_DATA', {field: 'managers', data: managers});
    },
    async fetchUser(context, {
        userId,
        queryString = ''
    }) {
        if (queryString.indexOf('include') === -1) {
            queryString = `${queryString}&include=groups,user_permissions`;
        }

        const response = await authedAxios.get(`/users/${userId}/?${queryString}`);

        context.commit('SET_USER_EDITING', utils.dataFormatter.deserialize(response.data));

        return response;
    },
    async fetchUsers(context, queryObject) {
        const queryString = asString(queryObject, {
                ...defaultParamMap,
                'search': 'search',
                'email': 'filter[email]',
                'firstName': 'filter[first_name]',
                'idNumber': 'filter[id_number]',
                'isActive': 'filter[is_active]',
                'isAdUser': 'filter[is_ad_user]',
                'lastName': 'filter[last_name]',
                'twoFA': 'filter[two_fa]',
                'twoFactorSetup': 'filter[two_factor_setup]',
                'username': 'filter[username]',
                'userType': 'filter[user_type]',
                'clientId': 'filter[client_id]',
                'personalPhoneNumber': 'filter[personal_phone_number]',
                'workPhoneNumber': 'filter[work_phone_number]',
                'textNotifications': 'filter[text_notifications]',
                'department': 'filter[department]',
                'manager': 'filter[manager]',
            }),
            response = await authedAxios.get(`/users/?${queryString}`, {signal: context.state.abortController?.signal});

        context.commit('SET_USERS', utils.dataFormatter.deserialize(response.data));
        context.commit('SET_DATA', {field: 'totalUserCount', data: response.data.meta.pagination.count});

        return response;
    },
    async fetchSystems(context, {
        systems = {},
        queryObject,
    }) {
        let queryString = asString(queryObject, {
                ...defaultParamMap,
                'search': 'search',
                'limit': 'limit',
            }),
            systemsFilter = Object.keys(systems).length > 0 ? `filter[name]=${Object.keys(systems).join(',')}` : '';
        const response = await authedAxios.get(
                `/systems/?${systemsFilter}${queryString}`),
            cleanedSystems = uniqBy(utils.dataFormatter.deserialize(response.data), 'appLabel');

        context.commit('SET_SYSTEMS', cleanedSystems);
        context.commit('SET_DATA', {field: 'totalSystemCount', data: response.data.meta.pagination.count});

        return response.data;
    },
    async fetchSystemPermissions(context, {
        appLabel = null,
        queryObject = '',
    }) {
        let queryString = asString(queryObject, {
            ...defaultParamMap,
        });

        const response = await authedAxios.get(`/permissions/?filter[content_type]=${appLabel}${queryString}`),
            systemPermissions = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_DATA', {field: 'totalPermissionCount', data: response.data.meta.pagination.count});

        return systemPermissions;
    },
    async fetchFilteredUsers(context, {
        filter = '',
        value = false,
        setStateKey = '',
    }) {
        const response = await authedAxios.get(`/users/?filter[${filter}]=${value}`);

        context.commit('SET_FILTERED_USERS', {
            key: setStateKey,
            response: utils.dataFormatter.deserialize(response.data),
        });
    },
    async searchForUser(context, {
        search = '',
        token = '',
    }) {
        const response = await authedAxios.get(`/users/?search=${search}`,
            token ? {headers: {'Authorization': token}} : null,
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async saveUser(context, {
        data,
        method = 'post',
        noFetch = false,
    }) {
        let response = await authedAxios[method.toLowerCase()](
            `users/${method !== 'post' ? data.id + '/' : ''}`,
            utils.dataFormatter.serialize({
                stuff: {
                    ...data,
                    type: 'User',
                },
            }),
            data.resettingToken ? {headers: {'Authorization': data.resettingToken}} : null,
        );

        if (!noFetch) {
            context.dispatch('fetchUsers', {
                queryObject: method === 'post' ? '&page=1' : '',
            });
        }

        return response.data ? utils.dataFormatter.deserialize(response.data) : {};
    },
    async syncSingleSignOnUsers() {
        let response = await authedAxios.post('users/sso-sync/', utils.dataFormatter.serialize({
            stuff: {
                type: 'SSO',
                id: '1',
            },
        }));

        return utils.dataFormatter.deserialize(response.data);
    },
    async updateUsersTwoFactorConfiguration(context, data) {
        let response = await authedAxios.post('users/two-factor-configuration/', utils.dataFormatter.serialize({
            stuff: {
                type: 'Two-Factor Configuration',
                ...data,
            },
        }));

        return utils.dataFormatter.deserialize(response.data);
    },

    async searchForGroup(context, {search = ''}) {
        const response = await authedAxios.get(`/groups/?search=${search}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchGroup(context, {
        groupId,
        queryString = '',
    }) {
        if (queryString.indexOf('include') === -1) {
            queryString = `${queryString}&include=members,permissions`;
        }

        const response = await authedAxios.get(`/groups/${groupId}/?${queryString}`);

        context.commit('SET_GROUP_EDITING', utils.dataFormatter.deserialize(response.data));

        return response;
    },
    async fetchGroups(context, {queryObject}) {
        let queryString = asString(queryObject, {
            ...defaultParamMap,
            'search': 'search',
        });
        const response = await authedAxios.get(`/groups/?${queryString}`, {signal: context.state.abortController?.signal});

        context.commit('SET_GROUPS', utils.dataFormatter.deserialize(response.data));
        context.commit('SET_DATA', {field: 'totalGroupCount', data: response.data.meta.pagination.count});

        return response;
    },
    async saveGroup(context, {
        data,
        method = 'post',
    }) {
        let groupDefaults = {
            type: 'Group',
        };

        const response = await authedAxios[method.toLowerCase()](
            `groups/${method === 'post' ? '' : `${data.id}/`}`,
            utils.dataFormatter.serialize({
                stuff: {
                    ...groupDefaults,
                    ...data,
                },
                includeNames: [],
            }),
        );

        context.dispatch('fetchGroups', {
            queryString: method === 'post' ? '&page=1' : '',
        });

        return response.data ? utils.dataFormatter.deserialize(response.data) : {};
    },
    async deleteGroup({dispatch}, groupId) {
        const response = await authedAxios.delete(`groups/${groupId}/`);

        dispatch('fetchGroups');

        return response.status === 204;
    },
    async fetchAlert(context, {
        alertId,
        queryString = '',
    }) {
        if (!queryString.includes('include')) {
            queryString = `include=created_by,updated_by${queryString}`;
        }

        const response = await authedAxios.get(`alerts/${alertId}/?${queryString}`);

        context.commit('SET_ALERT_EDITING', utils.dataFormatter.deserialize(response.data));

        return response;
    },
    async fetchAlerts(context, {
        queryObject,
        include = '?include=created_by,updated_by',
    }) {

        let queryString = asString(queryObject, {
            ...defaultParamMap,
            'search': 'search',
            'alerts': 'alerts'
        });

        const response = await authedAxios.get(`alerts/${include}${queryString}`, {signal: context.state.abortController?.signal});

        context.commit('SET_ALERTS', utils.dataFormatter.deserialize(response.data));
        context.commit('SET_DATA', {field: 'totalAlertCount', data: response.data.meta.pagination.count});

        return response;
    },
    async saveAlert(context, {
        data,
        method = 'post',
    }) {
        const response = await authedAxios[method.toLowerCase()](
            `alerts/${method === 'post' ? '' : `${data.id}/`}`,
            utils.dataFormatter.serialize({
                stuff: {
                    type: 'Alert',
                    ...data,
                },
                includeNames: [],
            }),
        );

        context.dispatch('fetchAlerts', {});

        return response.data ? utils.dataFormatter.deserialize(response.data) : {};
    },
    async deleteAlert({dispatch}, alertId) {
        const response = await authedAxios.delete(`alerts/${alertId}/`);

        dispatch('fetchAlerts', {});

        return response.status === 204;
    },
    async fetchTokenData(context, {
        token,
        url = '/tokens/',
    }) {
        const response = await authedAxios.get(
            url,
            {headers: {'Authorization': token}},
        );

        return response.data.data;
    },
    async sendPasswordResetEmail(context, {
        data,
        url = '/tokens/',
    }) {
        const response = await authedAxios.post(
            url,
            utils.dataFormatter.serialize({
                stuff: {
                    ...data,
                    type: 'Token',
                },
            }),
        );

        return response.data;
    },
    async stopImpersonate() {
        let response = await authedAxios.post('/impersonate/');

        setCookieValue('auth', response.data.auth, 1);
    },
    async resetTwoFactor(state, {userId, currentTwoFactor, override}) {
        const response = await authedAxios.post(`/users/reset-two-factor/`, {
            data: {
                type: 'ResetTwoFactor',
                attributes: {
                    user_id: userId,
                    current_two_factor: currentTwoFactor,
                    override: override,
                }
            }
        });

        return utils.dataFormatter.deserialize(response.data);
    },
    async searchForPermission(context, {
        search = '',
        token = '',
    }) {
        const response = await authedAxios.get(`/permissions/?include=content_type&search=${search}`,
            token ? {headers: {'Authorization': token}} : null,
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchPermission(context, {permissionId = null}) {
        const response = await authedAxios.get(`/permissions/${permissionId}/?include=users,groups`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async savePermission(context, {
        permissionId = null,
        data = {},
    }) {
        const response = await authedAxios.patch(
            `/permissions/${permissionId}/?include=users,groups`,
            utils.dataFormatter.serialize({
                stuff: {
                    type: 'Permission',
                    ...data,
                },
            }),
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchActivities(context, {
        filters = {},
        url = '/activity/?include=user',
        queryObject
    } = {}) {
        let queryString = asString(queryObject, {
            ...defaultParamMap,
        });
        if (Object.keys(filters).length) {
            url += `${Object.keys(filters).map((key) => {
                return `&filter[${key}]=${filters[key]}`;
            }).join('')}`;
        }

        const response = await authedAxios.get(url + queryString);

        let activities = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_DATA', {field: 'totalActivityCount', data: response.data.meta.pagination.count});

        context.commit('SET_ACTIVITIES', activities);
    },
};

export const mutations = {
    SET_AUTH_TOKEN(state, token) {
        state.authToken = token;
        axios.defaults.headers.Authorization = token;
        authedAxios.defaults.headers.Authorization = token;
    },
    SET_DATA(state, {field, data}) {
        state[field] = data;
    },
    SET_FILTERED_USERS(state, {
        key,
        response,
    }) {
        state[key] = response;
    },
    SET_LOGIN_ALERT(state, message) {
        state.loginAlert = message;
    },
    SET_USERS(state, response) {
        state.users = response;
    },
    SET_SYSTEMS(state, systems) {
        state.systems = systems;
    },
    SET_USER_EDITING(state, user) {
        state.userEditing = user;
    },
    SET_SSO_SYNCING(state, syncing) {
        state.sso.syncing = syncing;
    },
    SET_GROUPS(state, response) {
        state.groups = response;
    },
    SET_GROUP_EDITING(state, group) {
        state.groupEditing = group;
    },
    SET_ACTIVITIES(state, response) {
        state.activities = response;
    },
    SET_ALERTS(state, response) {
        state.adminAlerts = response;
    },
    SET_ALERT_EDITING(state, alert) {
        state.alertEditing = alert;
    },
    CLEAR_SEARCH_FIELDS(state) {
        state.searchFields = {};
    },
    SET_SEARCH_FIELDS(state, searchField) {
        if ((typeof searchField.value !== 'boolean' && !searchField.value) || (Array.isArray(searchField.value) && !searchField.value.length)) {
            Vue.delete(state.searchFields, searchField.key);
        } else {
            Vue.set(state.searchFields, searchField.key, searchField.value);
        }
    },
};

export const state = () => {
    return {
        abortController: undefined,
        loginMessage: {
            variant: 'info',
            content: null,
        },
        loginAlert: null,

        authToken: getters.getTokenFromCookie(),
        sso: {
            syncing: false,
        },

        searchFields: {},

        activities: [],
        groupEditing: {},
        groups: [],
        systems: [],
        totalGroupCount: 0,
        totalSystemCount: 0,
        totalPermissionCount: 0,
        totalActivityCount: 0,

        departments: [],
        managers: [],
        userEditing: {},
        users: [],
        totalUserCount: 0,

        adminAlerts: [],
        alertEditing: {},
        totalAlertCount: 0,
    };
};

export default new Vuex.Store({
    modules: {
        activity,
        alerts,
        configuration,
        notifications,
        toolbox,
        twoFactor,
        unauthenticated,
    },
    getters,
    actions,
    mutations,
    state: state(),
});
